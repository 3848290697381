import React, { useState, useEffect, lazy, Suspense } from "react";
import Spinner from "./components/Spinner";

const Navbar = lazy(() => import("./components/Navbar"));
const Hero = lazy(() => import("./components/Hero"));
const Featured = lazy(() => import("./components/Featured"));
const Article = lazy(() => import("./components/Article"));
const Teams = lazy(() => import("./components/Teams"));
const Footer = lazy(() => import("./components/Footer"));

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="relative bg-slate-100">
      <Suspense
        fallback={
          <div className="flex h-screen items-center justify-center">
            <Spinner />
          </div>
        }
      >
        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <Navbar />
            <Hero />
            <Featured />
            <Article />
            <Teams />
            <Footer />
          </>
        )}
      </Suspense>
    </div>
  );
};

export default App;
